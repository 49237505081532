// Generated by Framer (e83dae3)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as sharedStyle1 from "../css/GL_SiaOCQ";
import * as sharedStyle from "../css/J2o0w94ro";

const cycleOrder = ["yvK4RyXTw"];

const serializationHash = "framer-YbJri"

const variantClassNames = {yvK4RyXTw: "framer-v-66xbfj"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({heading, height, id, text, width, ...props}) => { return {...props, EiGHJVEI_: text ?? props.EiGHJVEI_ ?? "Manrope", rbM3uEfWD: heading ?? props.rbM3uEfWD ?? "Font"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;heading?: string;text?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, rbM3uEfWD, EiGHJVEI_, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "yvK4RyXTw", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = [sharedStyle.className, sharedStyle1.className]

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} className={cx(serializationHash, ...sharedStyleClassNames, "framer-66xbfj", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"yvK4RyXTw"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}}><RichText __fromCanvasComponent children={<React.Fragment><motion.p className={"framer-styles-preset-cs8fwb"} data-styles-preset={"J2o0w94ro"}>Font</motion.p></React.Fragment>} className={"framer-vqqze5"} data-framer-name={"Subheading"} layoutDependency={layoutDependency} layoutId={"wYRQi7NAM"} style={{"--framer-paragraph-spacing": "0px"}} text={rbM3uEfWD} verticalAlignment={"top"} withExternalLayout/><motion.div className={"framer-ta3489"} data-framer-name={"Divider"} layoutDependency={layoutDependency} layoutId={"TXRJCT4UU"} style={{backgroundColor: "var(--token-4f89ecfa-9ced-4828-96de-7dbbdb3044ba, rgb(204, 204, 204))"}}/><RichText __fromCanvasComponent children={<React.Fragment><motion.p className={"framer-styles-preset-1yqhea4"} data-styles-preset={"GL_SiaOCQ"}>Manrope</motion.p></React.Fragment>} className={"framer-12shym4"} data-framer-name={"Info"} layoutDependency={layoutDependency} layoutId={"k5y3IYgXR"} style={{"--framer-paragraph-spacing": "0px"}} text={EiGHJVEI_} verticalAlignment={"top"} withExternalLayout/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-YbJri.framer-1fjn2wf, .framer-YbJri .framer-1fjn2wf { display: block; }", ".framer-YbJri.framer-66xbfj { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 5px; height: min-content; justify-content: center; overflow: visible; padding: 0px 0px 0px 0px; position: relative; width: 310px; }", ".framer-YbJri .framer-vqqze5, .framer-YbJri .framer-12shym4 { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", ".framer-YbJri .framer-ta3489 { flex: 1 0 0px; height: 1px; overflow: hidden; position: relative; width: 1px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-YbJri.framer-66xbfj { gap: 0px; } .framer-YbJri.framer-66xbfj > * { margin: 0px; margin-left: calc(5px / 2); margin-right: calc(5px / 2); } .framer-YbJri.framer-66xbfj > :first-child { margin-left: 0px; } .framer-YbJri.framer-66xbfj > :last-child { margin-right: 0px; } }", ...sharedStyle.css, ...sharedStyle1.css]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 26
 * @framerIntrinsicWidth 310
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","auto"]}}}
 * @framerVariables {"rbM3uEfWD":"heading","EiGHJVEI_":"text"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramereGjU887Sv: React.ComponentType<Props> = withCSS(Component, css, "framer-YbJri") as typeof Component;
export default FramereGjU887Sv;

FramereGjU887Sv.displayName = "CMS/Product Info Item";

FramereGjU887Sv.defaultProps = {height: 26, width: 310};

addPropertyControls(FramereGjU887Sv, {rbM3uEfWD: {defaultValue: "Font", displayTextArea: false, title: "Heading", type: ControlType.String}, EiGHJVEI_: {defaultValue: "Manrope", displayTextArea: false, title: "Text", type: ControlType.String}} as any)

addFonts(FramereGjU887Sv, [...sharedStyle.fonts, ...sharedStyle1.fonts])